















import { Component, Watch } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import CommunityHeadingComponent from '../components/CommunityHeadingComponent.vue';
import CommunityDetailsComponent from '../components/CommunityDetailsComponent.vue';

import ErrorComponent from '../components/ErrorComponent.vue';
import { handleError } from '../lib/shared';

@Component({
  components: {
    ErrorComponent,
    CommunityHeadingComponent,
    CommunityDetailsComponent,
  },
})
export default class CommunityVue extends BaseComponent {

  protected baseRoute: string = '/communities';
  private arrErrors: Error[] = [];

  private handleError(error) {
    handleError(this.arrErrors, error);
  }
}
