





























import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import { getIndigenousCommunity } from '../store/rest.service';
import { IndigenousCommunity } from '../store/models';

@Component
export default class CommunityHeadingComponent extends BaseComponent {

  @Prop() public id: any;

  private communityLoading: boolean = false;
  private editedItem?: IndigenousCommunity|null = null;

  protected mounted() {
    this.getIndigenousCommunity();
  }

  private getIndigenousCommunity() {
    this.communityLoading = true;
    getIndigenousCommunity(this.id, true).then((response) => {
      this.editedItem = response;
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.communityLoading = false;
    });
  }

  private navReport() {
    const path: string = '/reports/indigenous-impact-community?community=' + this.id;
    this.$router.push({ path });
  }
}
