




































import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import SpinnerComponent from '../components/SpinnerComponent.vue';
import { getIndigenousCommunityContacts } from '../store/rest.service';
import { ResourceSearchResult, ContactForProjectsMeta } from '@/store/models';

@Component({
  components: {
    SpinnerComponent,
  },
})
export default class CommunityContactsComponent extends BaseComponent {

  @Prop() public communityId: any;

  private communityContactsLoading: boolean = false;
  private communityContacts?: ResourceSearchResult|null = null;
  private contactCount: number = 0;

  private pagination: any = { sortBy: 'person.formattedName', descending: false, page: 1, rowsPerPage: 999999 };

  private contactHeaders = [
    { text: 'Contact', align: 'left', value: 'person.formattedName', class: 'grid-header' },
    { text: 'Projects', align: 'left', sortable: false, class: 'grid-header' },
  ];


  protected mounted() {
    this.getIndigenousCommunityContacts();
  }

  private getIndigenousCommunityContacts() {
    this.communityContactsLoading = true;
    getIndigenousCommunityContacts(this.communityId).then((response) => {
      this.communityContacts = (response as ResourceSearchResult);
      this.contactCount = this.communityContacts.searchResults.results.length;
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.communityContactsLoading = false;
    });
  }
}
