






























































import { Component, Watch, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import { searchAggregatesById, searchAggregatesByRelation } from '../store/rest.service';
import { SearchResults, ResourceSearchResult, SortColumnMapping, ResultMeta, GeneralSearchCriteria, Aggregate, TotalsMeta } from '../store/models';
import ErrorComponent from './ErrorComponent.vue';
import SpinnerComponent from './SpinnerComponent.vue';
import ExpensesTableComponent from './ExpensesTableComponent.vue';
import { getTNBs, getSocialProcurement, getDIS } from '../lib/aggregate';
import { formatCurrencyToString, handleError } from '../lib/shared';
import * as _ from 'lodash';
import { getIconUrl } from '@/lib/images';
import { EmptyGeneralSearchCriteria } from '@/store/models-empty';

@Component({
  components: {
    ErrorComponent,
    SpinnerComponent,
    ExpensesTableComponent,
  },
})
export default class CommunityProjectsTableComponent extends BaseComponent {

  @Prop() private communityId: any;

  private getTNBs = getTNBs;
  private getSocialProcurement = getSocialProcurement;
  private getDIS = getDIS;

  private communityProjects: ResourceSearchResult|null = null;
  private communityProjectsLoading: boolean = false;
  private projectCount: number = 0;
  private projectSearchTotals: {[key: string]: TotalsMeta} = {};

  private arrErrors: Error[] = [];

  private bInitialLoad: boolean = true;
  private bInitialLoadWatch: boolean = true;

  // @ts-ignore
  private pagination: any = { sortBy: 'project.projectName', descending: false, page: 1, rowsPerPage: 25 };
  private rowsPerPage: number[] = [10, 25, 100];
  private searchTerms: string = '';
  private resetDate: number = 0;
  private expand: boolean = false;

  private sortMappings: SortColumnMapping[] = [
    {
      field: 'project.projectName',
      sortColumn: 'display-name',
    },
    {
      field: 'project.startDate',
      sortColumn: 'start-date',
    },
  ];

  private communityHeaders = [
    { text: 'Project Name', align: 'left', value: 'facetDetail.displayName', sortable: false, class: this.getColumnClass() },
    { text: 'View', align: 'left', class: 'grid-header', sortable: false },
    { text: 'Start Date', align: 'left', value: 'facetDetail.startDate', sortable: false, class: this.getColumnClass() },
    { text: 'Total Project Revenue', align: 'right', sortable: false, class: this.getColumnClass() },
    { text: 'Indigenous Benefits', align: 'right', sortable: false, class: this.getColumnClass() },
    { text: 'Total Project Expenses', align: 'right', value: 'totals.expenses.expenseTotals.totalProjectSpend', sortable: false, class: this.getColumnClass() },
    { text: 'Direct Indigenous Spend', align: 'right', value: 'totals.expense.indigenousSpend', sortable: false, class: this.getColumnClass() },
  ];

  protected changeCurrentOrg() {
    this.getProjects();
  }

  protected mounted() {
    this.mount();
  }

  private mount() {
    this.getProjects();
  }

  private isLoading(): boolean {
    return this.communityProjectsLoading;
  }

  private handleError(error) {
    handleError(this.arrErrors, error);
  }

  @Watch('pagination', { deep: true })
  private onPaginationChanged(val: any, oldVal: any) {
    if (!_.isEqual(val, oldVal) && !this.bInitialLoad) {
      if (!this.bInitialLoadWatch) {
        this.getProjects();
      }
    }
    this.bInitialLoad = false;
    this.bInitialLoadWatch = false;
  }

  private getTotalProjectRevenue(projectId: string) {
    const projectTotals = this.projectSearchTotals[projectId];
    if (projectTotals && projectTotals.revenue) {
      return formatCurrencyToString(projectTotals.revenue.revenueTotals.totalProjectRevenue.toString());
    }
    return "";
  }

  private getTotalProjectExpenses(projectId: string) {
    const projectTotals = this.projectSearchTotals[projectId];
    if (projectTotals && projectTotals.expenses) {
      return formatCurrencyToString(projectTotals.expenses.expenseTotals.totalProjectSpend.toString());
    }
    return "";
  }

  private getColumnClass(): string {
    return 'grid-header';
  }

  private editProject(projectId: string) {
    if (projectId !== '') {
      this.$router.push({ path: '/projects/id/' + projectId });
    }
  }

  private navImport() {
    this.changeRoute('/projects/import');
  }

  private getProjects() {
      this.getCommunityProjects();
  }

  private getCommunityProjects() {
    this.communityProjectsLoading = true;
    const searchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
    searchAggregatesByRelation(searchCriteria, 'icproject', this.communityId)
    .then((response) => {
      this.communityProjects = response;
      this.projectCount = +((this.communityProjects.searchResults as SearchResults).resultMeta as ResultMeta).matched;
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      // Get total project revenue and total project expenses from search endpoint
      const requests: Array<Promise<void>> = [];
      if (this.communityProjects) {
        for (const project of this.communityProjects.searchResults.results as Aggregate[]) {
        requests.push(searchAggregatesById(searchCriteria, null, project.facetDetail.identifier).then((response) => {
            if (response.searchResults.resultMeta.matched === 1 && response.searchResults.resultMeta.included === 1 ) {
              this.projectSearchTotals[project.facetDetail.identifier] = (response.searchResults.results[0] as Aggregate).totals as TotalsMeta;
            }
          }));
        }
      }

      // Wait for all requests to complete
      Promise.all(requests).finally(() => {
        this.communityProjectsLoading = false;
        this.bInitialLoad = false;
      });
    });
  }

  private getEditIcon(): string {
    return getIconUrl('icon_edit.png');
  }
}
