

















































import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import { getIndigenousCommunity, searchAggregatesByRelation } from '../store/rest.service';
import { IndigenousCommunity, ResourceSearchResult, GeneralSearchCriteria } from '../store/models';
import { EmptyGeneralSearchCriteria } from '../store/models-empty';
import SpinnerComponent from '../components/SpinnerComponent.vue';
import CommunityProjectsTableComponent from '../components/CommunityProjectsTableComponent.vue';
import CommunityContactsComponent from '../components/CommunityContactsComponent.vue';
import * as _ from 'lodash';

@Component({
  components: {
    SpinnerComponent,
    CommunityProjectsTableComponent,
    CommunityContactsComponent,
  },
})
export default class CommunityDetailsComponent extends BaseComponent {

  @Prop() private id: any;

  private searchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
  private communityAggregateUpdated: number = 0;
  private communityLoading: boolean = false;
  private communityAggregateLoading: boolean = false;
  private editedItem?: IndigenousCommunity|null = null;
  private communities: ResourceSearchResult|null = null;
  private communityAggregate: ResourceSearchResult|null = null;

  protected mounted() {
    this.getIndigenousCommunity();
    this.getIndigenousCommunityAggregate();
  }

  private getIndigenousCommunity() {
    this.communityLoading = true;
    getIndigenousCommunity(this.id, true).then((response) => {
      this.editedItem = response;
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.communityLoading = false;
    });
  }

  private getIndigenousCommunityAggregate() {
    this.communityAggregateLoading = true;
    searchAggregatesByRelation(this.searchCriteria, 'relatedic', this.currentOrg, this.id)
    .then((response) => {
      this.communityAggregate = response;
      this.communityAggregateUpdated++;
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.communityAggregateLoading = false;
    });
  }
}
